export default [
  {
    text: 'workflow.table.status',
    value: 'status',
  },
  {
    text: 'workflow.table.code',
    value: 'code',
  },
  {
    text: 'workflow.table.type',
    value: 'type',
  },
  {
    text: 'workflow.table.venues',
    value: 'venues',
    type: 'slot',
  },
];
