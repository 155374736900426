
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { Workflow } from '@/interfaces/models/Workflow';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { Venue } from '@/interfaces/models/Venue';

@Component({
  components: { VTable },
})
export default class WorkflowTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Workflow[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  public headers: ColumnHeader[] = headers;

  public getVenueNames(venues: string[]) {
    const names: string[] = [];
    for (const id of venues) {
      const venue: Venue = this.venues.find((item: Venue) => item._id === id)!;
      if (venue) {
        names.push(venue.name);
      }
    }

    return names;
  }
}
