
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import WorkflowTable from '@/components/workflow/WorkflowTable.vue';
import { Venue } from '@/interfaces/models/Venue';
import VEmptyState from '@/components/shared/VEmptyState.vue';

const workflow = namespace('workflow');
const app = namespace('app');

@Component({
  components: { VEmptyState, WorkflowTable, VWrapper },
})
export default class Workflow extends Vue {
  @workflow.Action('fetch') public getWorkflows!: any;
  @workflow.State('items') public workflows!: Workflow[];

  @app.State('venues') protected venues!: Venue[];
  @app.Action('fetchVenues') protected getVenues!: any;

  public async mounted() {
    this.$startLoading('venue');
    await this.getVenues();
    this.$stopLoading('venue');
    this.$startLoading('workflow');
    this.getWorkflows();
    this.$stopLoading('workflow');
  }
}
